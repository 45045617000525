
.articleDetails-container {
  padding: 3em 3em 10em 3em;

  .articleDetails-title {
    font-family: Anton, sans-serif;
    font-weight: normal;
    font-size: 3rem;
    color: var(--mainTextColor);
    padding-bottom: 0.2em;
  }

  .articleDetails-summary {
    padding-top: 4em;

    .articleDetails-pointLine {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1em;
      padding-bottom: 2em;

      .articleDetails-pointLine-dot {
        height: 1.3rem;
        width: 1.3rem;
        background-color: transparent;
        margin-top: 5px;
        border-radius: 50%;
        border: 3px solid var(--mintColor);
        box-sizing: border-box;
      }

      .articleDetails-pointLine-text {
        width: calc(95% - 20px);
        font-family: Roboto-Regular, sans-serif;
        color: var(--mainTextColor);
        font-size: 1.8rem;
      }
    }
  }

  .articleDetails-youtube {
    width: calc(100% - 4px);
    height: auto;
    aspect-ratio: 16/9;
    border-radius: 2em;
    overflow: hidden;
    z-index: 1;
    margin: 5em 0;
    iframe {
      width: 100%;
      height: 100%;
      border: none;

    }
  }

  .articleDetails-twitter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem;

    div {
      width: 100%;
      div {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }

    .twitter-tweet {
      max-width: 100%;
      height: auto;
    }
    #app {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .articleDetails-tradingview {
    margin-top: 4em;
    display: flex;
    height: 40rem;
    align-items: flex-start;
    gap: 1em;


    iframe {
      width: 100%;
      height: 500px;
      display: none;

    }
  }

  .articleDetails-numberOfSources {
    margin-top: 2em;
    font-family: Roboto-Bold, sans-serif;

    .articleDetails-numberOfSources-text {
      font-size: 2.2rem;
      color: var(--mainTextColor);
    }

    .articleDetails-numberOfSources-span {
      font-size: 2.4rem;
      color: var(--mintColor);
    }

  }

  //COPIED
  .articleDetails-grid {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 1.2em;
    margin-top: 2em;

    .articleDetails-grid-mainSource {
      width: calc(55% - 0.6em);

      a {
        text-decoration: none;
        color: var(--secondTextColor);

        .articleDetails-imageWrapper {
          overflow: hidden;
          display: inline-block;
          border-radius: 1rem;
          width: 100%;
          height: auto;


          .articleDetails-grid-mainSource-image {
            width: 100%;
            height: auto;
            transition: transform 0.5s ease-in-out;

            aspect-ratio: 16/9;
            box-sizing: border-box;
            margin-bottom: -1px;
            padding: 0;
            display: block;
            will-change: transform;
          }
        }

        .articleDetails-grid-mainSource-companyWrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0.5em 0 0.7em;
          gap: 0.5em;

          .companyLogo {
            height: 1.5rem;
            width: auto;
          }

          p {
            font-size: 1.3rem;
            font-family: Roboto-Regular, sans-serif;
          }
        }

        h4 {
          font-size: 1.8rem;
          font-weight: normal;
          font-family: Roboto-Medium, sans-serif;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
      }

      a:hover {

        .articleDetails-imageWrapper {

          .articleDetails-grid-mainSource-image {
            transform: scale(1.1);
          }
        }
      }

    }

    .articleDetails-grid-otherSources {
      display: flex;
      flex-direction: column;
      gap: 1.2em;
      width: calc(45% - 0.6em);

      .articleDetails-grid-sources-element {
        background-color: var(--bgSecondColor);
        width: 100%;
        padding: 1em;
        border-radius: 1rem;
        transition: background-color 0.5s;

        a {
          text-decoration: none;

          .articleDetails-imageWrapper {
            display: none;
          }

          .articleDetails-grid-sources-element-imgOnlyMobile {
            display: none;
          }

          .articleDetails-grid-sources-element-companyWrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5em;
            padding-bottom: 1em;

            .companyLogo {
              height: 1.5rem;
              width: auto;
            }


            .articleDetails-grid-sources-element__sourceName {
              font-family: Raleway-Regular, sans-serif;
              font-size: 1.1rem;
              color: var(--mainTextColor);
            }
          }

          .articleDetails-grid-sources-element__title {
            font-family: Roboto-Medium, sans-serif;
            font-size: 1.4rem;
            color: var(--mainTextColor);
            font-weight: normal;


            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }

        }
      }

      .articleDetails-grid-sources-element:hover {
        background-color: var(--mintBackgroundColor);
      }

      .articleDetails-grid-otherSources-showAll {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: var(--bgSecondColor);
        padding: 1em;
        border-radius: 1rem;
        z-index: 2;
        transition: background-color 0.5s;
        cursor: pointer;

        .arrowIconAllSources {
          margin-right: 1em;
          transition: 0.5s;
          will-change: translate;

        }

        .articleDetails-grid-otherSources-showAll-textAndIcon {
          display: flex;
          width: 100%;
          gap: 1em;

          p {
            font-family: Roboto-Regular, sans-serif;
            font-size: 1.4rem;
            color: var(--mainTextColor);
          }
        }
      }

      .articleDetails-grid-otherSources-showAll:hover {
        background-color: var(--mintBackgroundColor);

        .arrowIconAllSources {
          transform: translateX(1rem);
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .articleDetails-container {
    padding: 3em 2rem 10em 2rem;

    .articleDetails-title {
      font-size: 2.5rem;
    }

    .articleDetails-grid {
      display: flex;
      flex-direction: row;
      //gap: 1.2em;
      gap: 2em;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      width: 100%;
      margin-left: 0;

      .articleDetails-grid-mainSource {
        flex: 0 0 85%;
        scroll-snap-align: start;
      }

      .articleDetails-grid-otherSources {
        flex-direction: row;
        //gap: 1.2em;
        gap: 2em;
        flex: 0 0 85%;

        .articleDetails-grid-sources-element {
          flex: 0 0 100%;
          scroll-snap-align: start;
          padding: 0;
          background-color: transparent;
          width: 100%;

          a {
            .articleDetails-imageWrapper {
              overflow: hidden;
              display: inline-block;
              border-radius: 1rem;
              width: 100%;
              height: auto;
            }

            .articleDetails-grid-sources-element-imgOnlyMobile {
              display: block;
              width: 100%;
              height: auto;
              box-sizing: border-box;
              margin-bottom: -1px;
              padding: 0;
              aspect-ratio: 16/9;
              object-fit: cover;
            }

            .articleDetails-grid-sources-element-companyWrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0.5em 0 0.7em;
              gap: 0.5em;
            }
            .articleDetails-grid-sources-element__title {
              font-size: 1.8rem;
              font-weight: normal;
              font-family: Roboto-Medium, sans-serif;
            }
          }

        }
        //.tmptmptmp {
        //  display: block;
        //  width: 100%;
        //  height: auto;
        //  box-sizing: border-box;
        //  margin-bottom: -1px;
        //  padding: 0;
        //  aspect-ratio: 16/9;
        //  object-fit: cover;
        //  border-radius: 1rem;
        //
        //}

        .articleDetails-grid-sources-element:hover {
          background-color: transparent;
        }

        .articleDetails-grid-otherSources-showAll {
          flex: 0 0 85%;
          scroll-snap-align: start;
          display: none;
        }

        .articleContent-grid-sources-element-showAllBig {
          flex: 0 0 100%;
          scroll-snap-align: start;
          width: 100%;
          .articleContent-sources-element-banner-wrapper {
            display: block;
            width: 100%;
            height: auto;
            box-sizing: border-box;
            margin-bottom: -1px;
            aspect-ratio: 16/9;
            object-fit: cover;
            border-radius: 1rem;
            //padding-right: 2rem;

            .articleContent-sources-element-banner {
              background-color: var(--bgSecondColor);
              width: 100%;
              height: 100%;
              border-radius: 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 1em;
              p {
                font-size: 1.8rem;
                font-family: Roboto-Medium, sans-serif;
                color: var(--mainTextColor);
              }
              img {
                height: 1.8rem;
                width: auto;
              }
            }
          }
        }
      }
    }

    .articleDetails-grid::-webkit-scrollbar {
      display: none;
    }
  }
}