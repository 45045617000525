.service-data {
  width: 100%;
  padding: 3em 3em 10em 3em;
}
.service-data h1 {
  color: var(--mainTextColor);
  font-size: 2.8rem;
  margin-bottom: 1em;
}
.service-data h2 {
  color: var(--mainTextColor);
  font-size: 2.2rem;
  padding: 1.5em 0 0.3em 0;
}
.service-data .secondH2 {
  padding: 0 0 0.3em 0;
}
.service-data p, .service-data li, .service-data ul, .service-data small {
  color: var(--thirdTextColor);
  font-size: 1.4rem;
}
.service-data a {
  color: #07C3FF;
}
.service-data li {
  margin-left: 1.7rem;
}

@media (max-width: 800px) {
  .service-data {
    padding: 2rem 3em 10em 2rem;
  }
}

