article {
  border-bottom: 1px solid var(--borderColor);
}
article .articleContent {
  padding: 3em 1.5em 3em 3em;
  cursor: pointer;
}
article .articleContent .articleContent-mainInfo h2 {
  font-size: 2.2rem;
  margin-left: 3.5rem;
  padding: 0.2em 0;
  font-weight: normal;
  font-family: Roboto-Medium, sans-serif;
  color: var(--mainTextColor);
}
article .articleContent .articleContent-mainInfo .articleContent-text {
  margin-left: 3.5rem;
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  font-size: 1.8rem;
  font-family: Roboto-Regular, sans-serif;
  color: var(--mainTextColor);
}
article .articleContent .articleContent-mainInfo .articleContent-text span {
  color: var(--mintColor);
  font-family: Roboto-Bold, sans-serif;
  font-size: 2rem;
}
article .articleContent .articleContent-sources {
  margin-left: 3.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2em;
  width: calc(100% - 3.5rem);
}
article .articleContent .articleContent-sources .articleContent-sources-element {
  background-color: var(--bgSecondColor);
  width: calc(50% - 0.6em);
  padding: 1em;
  border-radius: 1rem;
  transition: background-color 0.5s;
  height: 100%;
  min-height: 83px;
}
article .articleContent .articleContent-sources .articleContent-sources-element a {
  text-decoration: none;
}
article .articleContent .articleContent-sources .articleContent-sources-element a .articleContent-sources-element-companyWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1em;
  gap: 0.5em;
}
article .articleContent .articleContent-sources .articleContent-sources-element a .articleContent-sources-element-companyWrapper .articleContent-sources-element__sourceName {
  font-family: Raleway-Regular, sans-serif;
  font-size: 1.1rem;
  color: var(--mainTextColor);
}
article .articleContent .articleContent-sources .articleContent-sources-element a .articleContent-sources-element-companyWrapper .companyLogo {
  height: 1.5rem;
  width: auto;
}
article .articleContent .articleContent-sources .articleContent-sources-element a .articleContent-sources-element__title {
  font-family: Roboto-Medium, sans-serif;
  font-size: 1.6rem;
  color: var(--mainTextColor);
  font-weight: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
article .articleContent .articleContent-sources .articleContent-sources-element:hover {
  background-color: var(--mintBackgroundColor);
}
article .articleContent .articleContent-sources .articleContent-sources-element-showAll {
  flex: 0 0 85%;
  padding-right: 2em;
}
article .articleContent .articleContent-sources .articleContent-sources-element-showAll .articleContent-sources-element-showAll-banner {
  flex: 0 0 85%;
  scroll-snap-align: start;
  max-height: 83px;
  object-fit: cover;
  width: 100%;
  border-radius: 1rem;
  background-color: var(--bgSecondColor);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
article .articleContent .articleContent-sources .articleContent-sources-element-showAll .articleContent-sources-element-showAll-banner p {
  font-size: 1.6rem;
  font-family: Roboto-Medium, sans-serif;
  color: var(--mainTextColor);
}
article .articleContent .articleContent-sources .articleContent-sources-element-showAll .articleContent-sources-element-showAll-banner img {
  height: 1.6rem;
  width: auto;
}
article .articleContent .showMore-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 3em;
  gap: 1em;
}
article .articleContent .showMore-wrapper .showMore-text {
  font-family: Raleway-Regular, sans-serif;
  font-size: 1.5rem;
  color: var(--mainTextColor);
}
article .articleContent .showMore-wrapper .showMore-img {
  rotate: 90deg;
  height: 2rem;
  width: auto;
  transition: 0.3s;
}
article .articleContent:hover .showMore-wrapper .showMore-img {
  transform: translatex(0.5rem);
}

@media (max-width: 800px) {
  article .articleContent {
    padding-left: 2rem;
    padding-right: 0;
  }
  article .articleContent .articleContent-mainInfo h2 {
    margin-left: 0;
    padding: 0.5em 2rem 0.5em 0;
    font-size: 2rem;
  }
  article .articleContent .articleContent-mainInfo .articleContent-text {
    margin-left: 0;
  }
  article .articleContent .articleContent-sources {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    gap: 2em;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  article .articleContent .articleContent-sources .articleContent-sources-element {
    flex: 0 0 85%;
    scroll-snap-align: start;
  }
  article .articleContent .showMore-wrapper {
    padding-right: 2rem;
  }
  article .articleContent .articleContent-sources::-webkit-scrollbar {
    display: none;
  }
}

