nav {
  position: sticky;
  top: 0;
  z-index: 6;
  transition: height 0.5s;
  will-change: height;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
nav .nav-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100px;
  position: sticky;
  top: -1px;
  border-bottom: 1px solid var(--borderColor);
  background-color: var(--bgColor);
  z-index: 6;
}
nav .logoWrapperSecond {
  display: flex;
  flex-direction: column;
  width: 100%;
}
nav .logoWrapperSecond .logoWrapper {
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 0 1em;
}
nav .logoWrapperSecond .logoWrapper img {
  width: auto;
  height: 3.5rem;
}
nav .logoWrapperSecond .logoWrapper .hamburger {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1em;
}
nav .logoWrapperSecond .logoWrapper .hamburger .hamburger-label {
  width: 3rem;
  height: 2px;
  background-color: var(--thirdTextColor);
}
nav .logoWrapperSecond .logoWrapper .profileTMP {
  width: 50px;
  height: 11px;
}
nav .logoWrapperSecond .buttonWrapperSecond {
  display: flex;
}
nav .nav-button {
  position: relative;
  flex: 1;
  height: 100%;
  background: none;
  border: none;
  font-size: 2rem;
  font-family: Raleway-SemiBold, sans-serif;
  cursor: pointer;
  text-align: center;
}
nav .nav-button .navButton-text {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.5em;
  height: 100%;
  font-size: 2rem;
  color: var(--thirdTextColor);
  transition: 0.3s;
  font-weight: normal;
}
nav .activeSort .navButton-text {
  color: var(--mainTextColor);
}
nav .underline {
  position: absolute;
  bottom: -2px;
  height: 3px;
  background-color: var(--mintColor);
  transition: left 0.5s ease, width 0.5s ease;
}
nav .nav-postAndSourcePage {
  position: relative;
  flex: 1;
  height: 100%;
  background: none;
  border: none;
  font-size: 2rem;
  font-family: Raleway-SemiBold, sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav .nav-postAndSourcePage .nav-postAndSourcePage-wrapper {
  display: flex;
  gap: 0.5em;
  margin-right: auto;
  cursor: pointer;
  padding: 1em;
}
nav .nav-postAndSourcePage .nav-postAndSourcePage-wrapper img {
  height: 2.5rem;
  width: auto;
  transition: 0.5s;
  max-height: 2.5rem;
}
nav .nav-postAndSourcePage .nav-postAndSourcePage-wrapper p {
  font-family: Raleway-Regular, sans-serif;
  transition: 0.3s;
  color: var(--mainTextColor);
}
nav .nav-postAndSourcePage .nav-postAndSourcePage-wrapper:hover img {
  transform: translateX(-0.3em);
}
nav .nav-postAndSourcePage .nav-postAndSourcePage-wrapper:hover p {
  color: var(--mintColor);
}
nav .nav-postAndSourcePage .nav-postAndSourcePage-singleText {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.5em;
  height: 100%;
  font-size: 2rem;
  font-weight: normal;
  color: var(--mainTextColor);
}
nav .nav-postAndSourcePage::after {
  position: absolute;
  content: "";
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 3px;
  background-color: var(--mintColor);
  transform: translateX(50%);
}
nav .mobileCategoryNav-Wrapper {
  display: none;
}
nav .customBr {
  height: 1px;
}

nav::-webkit-scrollbar {
  display: none;
}

.nav-wrapper::-webkit-scrollbar {
  display: none;
}

@media (max-width: 800px) {
  nav .logoWrapperSecond .logoWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  nav .mobileCategoryNav-Wrapper {
    display: block;
    width: 100%;
    background-color: var(--bgColor);
    border-bottom: 1px solid var(--borderColor);
    z-index: 4;
  }
  nav .mobileCategoryNav-Wrapper .mobile-category-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    padding: 0.5em 2rem;
    will-change: transform;
  }
  nav .mobileCategoryNav-Wrapper .mobile-category-button .category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  nav .mobileCategoryNav-Wrapper .mobile-category-button .category .category-label {
    font-size: 2.2rem;
    font-family: Raleway-Regular, sans-serif;
    color: var(--mainTextColor);
  }
  nav .mobileCategoryNav-Wrapper .mobile-category-button .category .category-value {
    font-size: 2.2rem;
    font-family: Raleway-Bold, sans-serif;
    color: var(--mainTextColor);
  }
  nav .mobileCategoryNav-Wrapper .mobile-category-button img {
    width: auto;
    height: 2rem;
    transition: 0.3s ease-out;
  }
  nav .mobileCategoryNav-Wrapper:hover .mobile-category-button img {
    transform: translateX(1rem);
  }

  .nav-postAndSourcePage-wrapper p {
    display: none;
  }
}

