
.timerWrapper {
  .timerText {
    font-size: 1.4rem;
    font-family: Roboto-Light, sans-serif;
    //font-family: Raleway-Light, sans-serif;
    color: var(--secondTextColor);
    position: relative;
  }

  .timerText::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--mintColor);
    transform: translateY(100%);
  }
}

@media (max-width: 800px) {

  .timerWrapper {
    .timerText {
    }
  }
  
}