.layout {
  display: flex;
  justify-content: center;
  position: relative;
}
.layout .mainContent {
  width: 76%;
  max-width: 800px;
}

@media (max-width: 1300px) {
  .layout {
    width: 100%;
  }
  .layout .mainContent {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .layout .mainContent {
    width: 100%;
  }
}

