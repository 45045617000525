
.fetchError-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 6em 2em;


  .fetchError-img-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 3em;
    .fetchError-img {
      width: 150px;
      height: auto;
    }
  }

  .fetchError-text {
    text-align: center;
    font-size: 2.5rem;
    font-family: Raleway-Regular, sans-serif;
    color: var(--mainTextColor);

  }
}

@media (max-width: 800px) {

  .fetchError-wrapper {

    .fetchError-img {
      width: 120px;
    }

    .fetchError-text {
      font-size: 2rem;
    }
  }
  
}