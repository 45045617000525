.categoryWrapper {
  display: flex;
  align-items: center;
  gap: 1em;
}
.categoryWrapper .categoryIcon {
  width: 2.5rem;
  height: 2.5rem;
}
.categoryWrapper .categoryText {
  font-size: 1.6rem;
  font-family: Raleway-Regular, sans-serif;
  color: var(--secondTextColor);
  text-decoration: none;
  font-weight: normal;
}

@media (max-width: 800px) {
  .categoryWrapper .categoryIcon {
    width: 2rem;
    height: 2rem;
  }
}

