:root {
  --bgColor: #F3F3F3;
  --bgSecondColor: #ececec;
  --mintColor: #1ED88A;
  --mintBackgroundColor: #dadada;
  --borderColor: #D2D2D2;
  --mainTextColor: #111111;
  --secondTextColor: #131313;
  --thirdTextColor: #6B6B6B;
  --blurColor: #F3F3F3;
}
[data-theme="dark"] {
  --bgColor: #111111;
  --bgSecondColor: #1b1b1b;
  --mintColor: #1ED88A;
  --mintBackgroundColor: #333333;
  --borderColor: #D2D2D2;
  --mainTextColor: #F3F3F3;
  --secondTextColor: #E0E0E0;
  --thirdTextColor: #AAAAAA;
  --blurColor: #111111;
}

body {
  background-color: var(--bgColor);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
  background-color: var(--bgColor);

  /*flickering*/
  /*height: 100%;*/
  margin-right: calc(-1 * (100vw - 100%));
  overflow-y: auto;
}

/*html, body {*/
/*  width: 100%;*/
/*  overflow-x: hidden;*/
/*}*/
html {
  overflow-x: hidden;
}

body {
  width: 100%;
}

::selection {
  background: #1ED88A;
  color: #F6F6F6;
}

::-moz-selection {
  background-color: #1ED88A;
  color: #F6F6F6;
}

*::-webkit-scrollbar {
  width: 12px;
}

/**::-webkit-scrollbar-track {*/
/*  background: var(--trackScrollBar);*/
/*}*/

*::-webkit-scrollbar-thumb {
  background-color: #1ED88A;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #4dffb3;
}


@font-face {
  font-family: 'Raleway-Bold';
  src: local(Raleway-Bold), url("../fonts/Raleway-Bold.ttf");
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: local(Raleway-SemiBold), url("../fonts/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: 'Raleway-Regular';
  src: local(Raleway-Regular), url("../fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: 'Raleway-Medium';
  src: local(Raleway-Medium), url("../fonts/Raleway-Medium.ttf");
}

@font-face {
  font-family: 'Raleway-Light';
  src: local(Raleway-Light), url("../fonts/Raleway-Light.ttf");
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local(Roboto-Regular), url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: 'Roboto-Light';
  src: local(Roboto-Light), url("../fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local(Roboto-Medium), url("../fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local(Roboto-Bold), url("../fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: 'Anton';
  src: local(Anton), url("../fonts/Anton-Regular.ttf");
}
