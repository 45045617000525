
.sideMenuContainer {
  //width: 12%;
  width: 240px;
  height: 100svh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--borderColor);
  padding: 0 1em;
  max-width: 240px;

  .sideMenuFirstWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 2em;

    .sideMenuFirstWrapper-logoWrapper {
      display: flex;
      justify-content: center;
      height: 85px;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
        padding-right: 4em;
        padding-bottom: 1em;
        padding-top: 1em;
      }
      .signet {
        display: none;
      }
    }

    .buttonWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      max-height: 500px;

      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 100%;

        .categoryImg {
          display: none;
        }

        p {
          text-align: left;
          font-size: 2.2rem;
          font-family: Raleway-Medium, sans-serif;
          padding: 0.7em 0;
          //transition: color 0.3s;
          transition: 0.3s;
          color: var(--mainTextColor);
        }
      }

      .buttonWrapper-sourceButton {
        display: block;

      }

      button:hover {
        p {
          color: var(--mintColor);
        }
      }

      .active {
        font-weight: bolder;
      }

      .sourceList {
        max-height: 100%;
        overflow-y: auto;
        padding-right: 5px;
        position: relative;
      }
      .sourceList::-webkit-scrollbar {
        display: none;
      }

      .sourceList {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      .scrollIndicator {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 2rem;
          width: auto;
          rotate: 90deg;
        }

      }
    }
  }

  .sideMenuSecondWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    //gap: 1.5em;
    padding-bottom: 3em;

    .sideMenuSecondWrapper-themeButton {
      border: none;
      background-color: transparent;
      color: var(--mainTextColor);
      font-family: Roboto-Regular, sans-serif;
      font-size: 1.3rem;
      padding: 0.7em 0.7em 0.7em 0;
      transition: color 0.3s;
      cursor: pointer;
    }

    .sideMenuSecondWrapper-themeButton:hover {
      color: var(--mintColor);
    }
    a {
      width: 100%;
      font-family: Roboto-Regular, sans-serif;
      font-size: 1.3rem;
      text-decoration: none;
      color: var(--mainTextColor);
      padding: 0.7em 0.7em 0.7em 0;
      transition: color 0.3s;
    }

    a:hover {
      color: var(--mintColor);
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6;
}

@media (max-width: 1500px) {
  .sideMenuContainer {
    .sideMenuFirstWrapper {
      .sideMenuFirstWrapper-logoWrapper {
        img {
          padding-right: 2em;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .sideMenuContainer {
    width: 240px;
  }
  
}


@media (max-width: 800px) {
  .sideMenuContainer {
    display: flex;
    position: fixed;
    background-color: var(--bgColor);
    z-index: 7;
    width: 80vw;
    max-width: none;
    transition: 0.5s;
    //padding: 0;
    transform: translateX(-80vw);
    padding-left: 3em;
  }

  .expanded {
    position: fixed;
    top: 0;
    display: flex;
    //transition: 3s;
    //width: 80%;
    overflow: hidden;
    transform: translateX(0);
    height: 100vh;
  }
  .overlay {
    display: block;
    opacity: 1;
  }

}

